.request-menu {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-5-70);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    padding: 16px;
}

.request-menu .option {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    display: inline-flex;
    gap: 4px;
    cursor: pointer;
}

.request-menu .option:active {
    opacity: 0.7;
}

.request-menu .option:disabled {
    opacity: 0.5;
    cursor: auto;
}

.request-menu .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.popup-button-wrapper {
    display: flex;
}