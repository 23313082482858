.curators-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1;
    height: 100%;
    max-width: 560px;
}

.curators-content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 12px;
    width: 100%;
}

.curators-horizontal-group {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
}

@media only screen and (max-width: 1010px) {
    .curators-horizontal-group {
        flex-direction: column;
    }

    .curators-section {
        max-width: none;
    }
}

@media only screen and (max-width: 460px) {
    .curators-section {
        width: 100%;
    }
}