.arrow-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    background-color: var(--variable-collection-secondary-color-50);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 24px;
    height: 16px;
    cursor: pointer;
}

.arrow-button > svg > path {
    color: var(--variable-collection-primary-color-5);
}

.arrow-button:disabled, .arrow-button[disabled] {
    opacity: 50%;
    pointer-events: none;
    user-select: none;
    cursor: default;
}

.arrow-button:active {
    opacity: 50%;
}