.post-track-card {
    align-items: flex-start;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.post-track-card .track-info {
    align-items: center;
    display: flex;
}

.post-track-card .track-image {
    height: 64px;
    object-fit: cover;
    width: 64px;
}

.post-track-card .section {
    align-items: flex-start;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--variable-collection-primary-color-25);
    border-radius: 0px 8px 8px 0px;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.post-track-card .track-name {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: center;
    padding: 0px 16px;
    width: 100%;
}

.post-track-card .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    width: fit-content;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
}

.post-track-card .status {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-25);
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 4px 16px;
    width: 100%;
}

.post-track-card .status-wrapper {
    color: var(--variable-collection-primary-color-70);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-weight);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
    width: fit-content;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
}

@media only screen and (max-width: 380px) {
    .post-track-card .track-image {
        height: 54px;
        width: 54px;
    }
}