.history-request-card {
    align-items: center;
    display: flex;
    height: 48px;
    min-height: 48px;
    gap: 32px;
    width: 100%;
}

.history-request-card .info {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    width: 100%;
}

.history-request-card .playlist-name {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    justify-content: flex-start;
    flex: 4;
}

.history-request-card .icon-frame {
    display: flex;
    width: 16px;
    height: 16px;
}

.history-request-card .text-name-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
}

.history-request-card .text-name-wrapper.blur {
    filter: blur(4px);
}

.history-request-card .curator-name {
    align-items: center;
    display: flex;
    gap: 8px;
    flex: 4;
}

.history-request-card .date {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex: 2;
}

.history-request-card .actions {
    align-items: center;
    display: inline-flex;
    padding: 0px 16px;
}

.history-request-card .icon-frame > svg > path {
    fill: var(--variable-collection-secondary-color-75);
}

.history-request-card .action-button {
    all: unset;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.history-request-card .action-button:active {
    opacity: 0.7;
}

.history-request-card .request-status {
    width: 160px;
    min-width: 160px;
    cursor: auto;
}

.history-request-card.mobile .request-status {
    width: 48px;
    min-width: 48px;
}

.history-request-card .request-status:hover {
    background-color: var(--variable-collection-primary-color-5-50);
}

.history-request-card .reuqest-popup-wrapper {
    display: flex;
}

@media only screen and (max-width: 1120px) {
    .history-request-card {
        gap: 16px;
    }
}