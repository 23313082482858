.alert-card {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  background-color: var(--variable-collection-warning-color-60);
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 10px 16px;
  width: 400px;
}

.alert-card .warning-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

.alert-card .text-wrapper {
  align-self: stretch;
  color: var(--variable-collection-primary-color-20);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
}

.alert-card .div {
  align-self: stretch;
  color: var(--variable-collection-primary-color-20);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
}

.alert-card .close-box {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  justify-content: center;
}

@media only screen and (max-width: 390px) {
  .alert-card {
    width: 280px;
  }
}

@media only screen and (min-width: 391px) and (max-width: 600px) {
  .alert-card {
    width: 350px;
  }
}