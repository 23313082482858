.help-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-20);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-30);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 16px 16px;
    width: 100%;
    cursor: pointer;
}

.help-button:active {
    opacity: 0.7;
}

.help-button .button-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to right,
            var(--variable-collection-secondary-color-50) 0%,
            var(--variable-collection-approve-color-60) 100%);
    background-clip: text;
    color: var(--variable-collection-approve-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 400;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-fill-color: transparent;
    width: fit-content;
    text-align: center;
}