.sender-section {
    align-items: flex-end;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 100%;
}

.sender-section .stock-section {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-5-30);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow: hidden;
    padding: 4px 8px;
    width: 144px;
}

.sender-section .counter-frame {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
}

.sender-section .stock-line {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sender-section .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-weight);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
}

.sender-section .text-wrapper.dull {
    color: var(--variable-collection-primary-color-60);
}

.sender-section .text-wrapper.green {
    color: var(--variable-collection-secondary-color-75);
}

.sender-section .send-request-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    background: linear-gradient(to right,
            var(--variable-collection-secondary-color-50) 0%,
            var(--variable-collection-primary-color-50) 100%);
    background-color: var(--variable-collection-primary-color-50);
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    padding: 8px 16px;
    min-width: 154px;
    cursor: pointer;
}

.sender-section .send-request-button:active {
    opacity: 0.7;
}

.sender-section .send-request-button:disabled {
    opacity: 0.5;
    cursor: auto;
}

.sender-section .button-text {
    color: var(--variable-collection-primary-color-20);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 400;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}