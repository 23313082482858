.blank-layout {
  align-items: center;
  justify-content: center;
  background:
    url("/public/assets/SigmundPattern.svg"),
    var(--variable-collection-2-background-gradient);
  background-color: var(--variable-collection-primary-color-15);
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vh;
  max-height: 100dvh;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}