.loader-box {
  align-items: center;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  align-self: stretch;
}

.loader-box.background {
  background-color: var(--variable-collection-primary-color-25-5);
}

svg.loader-box-icon {
  fill: var(--variable-collection-secondary-color-50);
  color: var(--variable-collection-secondary-color-50);
}

@media only screen and (max-width: 460px) {
  .loader-box {
      height: 100%;
  }
}