.scraping-summary {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: var(--variable-collection-primary-color-5-20);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 8px;
}

.scraping-summary .top-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    width: 100%;
}

.scraping-summary .stat {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
}

.scraping-summary .total-title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.scraping-summary .total-count {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h4-primary-font-family);
    font-size: var(--h4-primary-font-size);
    font-style: var(--h4-primary-font-style);
    font-weight: var(--h4-primary-font-weight);
    letter-spacing: var(--h4-primary-letter-spacing);
    line-height: var(--h4-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.scraping-summary .history {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-50);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 8px;
    width: 100%;
}

.scraping-summary .empty {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    padding: 8px 0px;
    width: 100%;
}

.scraping-summary .history-text {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all; 
}

.scraping-summary .history-text.left {
    flex: 5;
}

.scraping-summary .history-text.right {
    text-align: right;
    flex: 2;
}

.scraping-summary .history-text.center {
    flex: 3;
}

.scraping-summary .history-card {
    align-items: center;
    display: flex;
    padding: 8px;
    width: 100%;
}

.scraping-summary .loader-box {
    padding: 32px 32px;
}

.scraping-summary .spinner-frame {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    margin-bottom: 9px;
}

@media only screen and (max-width: 380px) {
    .scraping-summary .total-count {
        font-family: var(--h5-primary-font-family);
        font-size: var(--h5-primary-font-size);
        font-style: var(--h5-primary-font-style);
        font-weight: var(--h5-primary-font-weight);
        letter-spacing: var(--h5-primary-letter-spacing);
        line-height: var(--h5-primary-line-height);
    }
}