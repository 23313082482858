.square-button-d1 {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-20);
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
}

.square-button-d1:active {
    opacity: 0.7;
}

.square-button-d1:disabled {
    opacity: 0.5;
    cursor: auto;
}