.template-dropdown-menu {
    align-items: flex-start;
    border-radius: 8px;
    display: flex;  
    flex-direction: column;
    overflow: hidden;
    background-color: var(--variable-collection-primary-color-5-70);
    -webkit-backdrop-filter: blur(4px) brightness(100%);
    backdrop-filter: blur(4px) brightness(100%);
    width: 290px;
}

.template-dropdown-menu .main-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 8px;
    padding: 8px;
    width: 100%;
}

.template-dropdown-menu .options {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 320px;
}

.template-dropdown-menu .option {
    border-radius: 4px;
    align-items: center;
    align-self: stretch;
    display: flex;
    width: 100%;
    cursor: pointer;
}

.template-dropdown-menu .new-option {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    background: linear-gradient(to right,
            var(--variable-collection-secondary-color-50) 0%,
            var(--variable-collection-primary-color-50) 100%);
    background-color: var(--variable-collection-primary-color-50);
    border-radius: 4px;
    padding: 4px 0px;
    width: 100%;
    cursor: pointer;
}

.template-dropdown-menu .new-option:active {
    opacity: 0.7;
}

.template-dropdown-menu .new-option > svg > path {
    fill: var(--variable-collection-primary-color-20);
}

.template-dropdown-menu .option:hover .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
}

.template-dropdown-menu .option.active .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
    cursor: auto;
}

.template-dropdown-menu .left-section {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
}

.template-dropdown-menu .track-image {
    object-fit: cover;
    border-radius: 4px;
    width: 32px;
    height: 32px;
}

.template-dropdown-menu .info {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 4px;
    padding-right: 4px;
}

.template-dropdown-menu .text-wrapper {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    flex: 1;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    overflow: hidden;
    text-overflow: ellipsis;
}

.template-dropdown-menu .text-wrapper.dull {
    color: var(--variable-collection-primary-color-60);
}

.template-dropdown-menu .default-card {
    background: linear-gradient(to top,
            var(--variable-collection-secondary-color-65) 0%,
            var(--variable-collection-primary-color-50) 100%);
    background-color: var(--variable-collection-primary-color-50);
    align-items: center;
    border-radius: 4px;
    display: flex;
    gap: 10px;
    justify-content: center;
    height: 32px;
    width: 32px;
}

.template-dropdown-menu .no-templates {
    display: flex;
    align-items: center;
    padding: 32px 0px;
    width: 100%;
    text-align: center;
}

.template-dropdown-menu .default-card > svg > path {
    fill: var(--variable-collection-primary-color-20);
}

.template-dropdown-menu .options::-webkit-scrollbar {
    width: 8px;
}

.template-dropdown-menu .options::-webkit-scrollbar-track {
    background: transparent;
}

.template-dropdown-menu .options::-webkit-scrollbar-thumb {
    background: var(--variable-collection-secondary-color-75);
    border-radius: 4px;
}

.template-dropdown-menu .options::-webkit-scrollbar-thumb:hover {
    background: var(--variable-collection-secondary-color-65);
}