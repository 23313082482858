.request-status {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-50);
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.request-status.iconAndTitle {
    justify-content: flex-start;
}

.request-status:hover {
    background-color: var(--variable-collection-primary-color-5-70);
}

.request-status .status-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 400;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.request-status .status-wrapper.all {
    color: var(--variable-collection-contrast-color);
}

.request-status .status-wrapper.all>svg>path {
    fill: var(--variable-collection-contrast-color);
}

.request-status .status-wrapper.pending {
    color: var(--variable-collection-warning-color-60);
}

.request-status .status-wrapper.pending>svg>path {
    fill: var(--variable-collection-warning-color-60);
}

.request-status .status-wrapper.featured {
    color: var(--variable-collection-approve-color-60);
}

.request-status .status-wrapper.featured>svg>path {
    fill: var(--variable-collection-approve-color-60);
}

.request-status .status-wrapper.suspended {
    color: var(--variable-collection-primary-color-70);
}

.request-status .status-wrapper.suspended>svg>path {
    fill: var(--variable-collection-primary-color-70);
}

.request-status .status-wrapper.expired {
    color: var(--variable-collection-primary-color-60);
}

.request-status .status-wrapper.expired>svg>path {
    fill: var(--variable-collection-primary-color-60);
}


.request-status .status-wrapper.discussing {
    color: var(--variable-collection-secondary-color-75);
}

.request-status .status-wrapper.discussing>svg>path {
    fill: var(--variable-collection-secondary-color-75);
}