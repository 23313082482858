.genre-box {
  align-items: flex-start;
  background-color: var(--variable-collection-primary-color-5-20);
  -webkit-backdrop-filter: blur(3px) brightness(100%);
  backdrop-filter: blur(3px) brightness(100%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.genre-box .actions {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  width: 100%;
}

.genre-box .input-box {
  all: unset;
  box-sizing: border-box;
  align-items: center;
  align-self: stretch;
  background-color: var(--variable-collection-primary-color-5-50);
  border-radius: 8px 0px 0px 0px;
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  min-width: 24px;
  width: 100%;
}

.genre-box .input-box::placeholder {
  text-align: center;
}

.genre-box .text-wrapper {
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  white-space: nowrap;
}

.genre-box .empty-genres {
  display: flex;
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 36px;
  width: 100%;
  margin-top: 16px;
}

.genre-box .genres {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 16px;
  padding: 0px 16px;
  padding-bottom: 16px;
  width: 100%;
  min-height: 68px;
}

.genre-box .button-text {
  color: var(--variable-collection-primary-color-20);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: 400;
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.genre-box .add-button {
  all: unset;
  box-sizing: border-box;
  align-items: center;
  background: linear-gradient(to left,
      var(--variable-collection-approve-color-60) 0%,
      var(--variable-collection-secondary-color-50) 100%);
  background-color: var(--variable-collection-secondary-color-50);
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 8px 32px;
  cursor: pointer;
}

.genre-box .search-button {
  all: unset;
  box-sizing: border-box;
  align-items: center;
  align-self: stretch;
  background: linear-gradient(to left,
      var(--variable-collection-secondary-color-50) 0%,
      var(--variable-collection-primary-color-50) 100%);
  background-color: var(--variable-collection-primary-color-50);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 8px 32px;
  cursor: pointer;
  width: 100%;
}

.genre-box .hint {
  all: unset;
  box-sizing: border-box;
  align-items: center;
  align-self: stretch;
  background-color: var(--variable-collection-primary-color-5-50);
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  cursor: pointer;
}

.genre-box .add-button:active,
.genre-box .search-button:active {
  opacity: 0.8;
}

.genre-box .add-button:disabled,
.genre-box .search-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.genre-box .hint:disabled {
  pointer-events: none;
  cursor: auto;
}

.genre-box .hint:active>svg>path {
  fill: var(--variable-collection-secondary-color-50);
}

.genre-box .loader-box {
  padding: 32px 32px;
}