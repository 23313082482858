.scoped-box {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-20);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}


.scoped-box .description {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
}

.scoped-box .title {
    background: linear-gradient(to right,
            var(--variable-collection-primary-color-70) 0,
            var(--variable-collection-secondary-color-50) 50%,
            var(--variable-collection-approve-color-60) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--variable-collection-approve-color-60);
    font-family: var(--h3-primary-font-family);
    font-size: var(--h3-primary-font-size);
    font-style: var(--h3-primary-font-style);
    font-weight: var(--h3-primary-font-weight);
    letter-spacing: var(--h3-primary-letter-spacing);
    line-height: var(--h3-primary-line-height);
    width: 440px;
    padding-bottom: 8px;
}

.scoped-box .subtitle {
    color: var(--variable-collection-primary-color-70);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    width: fit-content;
    text-align: center;
}

.scoped-box .loaderWrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    min-height: 88px;
}

.title-frame {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scoped-box .loader {
    width: 8px;
    height: 40px;
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    position: relative;
    background: currentColor;
    color: var(--variable-collection-secondary-color-50);
    box-sizing: border-box;
    animation: animloaderBig 0.3s 0.3s linear infinite alternate;
}

.scoped-box .loader::after,
.scoped-box .loader::before {
    content: '';
    width: 8px;
    height: 40px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    animation: animloaderBig 0.3s 0.45s linear infinite alternate;
}

.scoped-box .loader::before {
    left: -20px;
    animation-delay: 0s;
}

@keyframes animloaderBig {
    0% {
        height: 48px
    }

    100% {
        height: 4px
    }
}

@media only screen and (max-width: 840px) {
    .scoped-box .title {
        font-family: var(--h4-primary-font-family);
        font-size: var(--h4-primary-font-size);
        font-style: var(--h4-primary-font-style);
        font-weight: var(--h4-primary-font-weight);
        letter-spacing: var(--h4-primary-letter-spacing);
        line-height: var(--h4-primary-line-height);
        width: 316px;
    }
}

@media only screen and (max-width: 460px) {
    .scoped-box {
        height: 100%;
    }
}

@media only screen and (max-width: 460px) {
    .scoped-box {
        gap: 16px;
    }

    .scoped-box .title {
        padding-bottom: 4px;
        width: unset;
    }

    .scoped-box .title.expiration {
        width: unset;
    }

    .scoped-box .subtitle {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }
}