.submission-track-dropdown {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-20);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 8px;
    gap: 16px;
    width: 100%;
    cursor: pointer;
}

.submission-track-dropdown .left-section {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.submission-track-dropdown .track-image {
    height: 32px;
    object-fit: cover;
    width: 32px;
    border-radius: 4px;
}

.submission-track-dropdown .info {
    align-items: center;
    display: inline-flex;
    gap: 4px;
}

.submission-track-dropdown .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all; 
}

.submission-track-dropdown .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}