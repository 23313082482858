.template-dropdown-box {
    align-items: flex-start;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 48px;
    background-position: 50% 50%;
    background-size: cover;
    cursor: pointer;
}

.template-dropdown-box .template-dropdown {
    background-color: var(--variable-collection-primary-color-5-20);
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    padding: 8px 16px;
}

.template-dropdown-box .template-dropdown.image {
    background-color: transparent;
    -webkit-backdrop-filter: blur(2px) brightness(70%);
    backdrop-filter: blur(2px) brightness(70%);
}

.template-dropdown-box .template {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
}

.template-dropdown-box .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}