/* Beat Spinner */
.loader.beat {
    width: 4px;
    height: 20px;
    border-radius: 4px;
    display: block;
    margin: 10px auto;
    position: relative;
    background: currentColor;
    color: var(--variable-collection-secondary-color-50);;
    box-sizing: border-box;
    animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loader.beat::after,
.loader.beat::before {
    content: '';
    width: 4px;
    height: 20px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    box-sizing: border-box;
    animation: animloader 0.3s 0.45s linear infinite alternate;
}

.loader.beat::before {
    left: -10px;
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        height: 24px
    }

    100% {
        height: 2px
    }
}


/* Dots Spinners */
.loader.dots, .loader.dots:before, .loader.dots:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader.dots {
    color: #FFF;
    font-size: 2px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader.dots:before,
  .loader.dots:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader.dots:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader.dots:after {
    left: 3.5em;
  }
  
  @keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
  }
      