.submission-request-box {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-5-20);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    width: 100%;
}

.submission-request-box .template-menu {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 16px;
    width: 100%;
}

.submission-request-box .actions {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.submission-request-box .popup-wrapper {
    display: flex;
    width: 100%;
}

.submission-request-box .main-frame {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}