.request-template {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.request-template .title-box {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-20);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    overflow: hidden;
    padding: 12px 16px;
    width: 100%;
}

.request-template .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.request-template .highlight {
    color: var(--variable-collection-secondary-color-75);
}

.request-template .content-box-frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    width: 100%;
}

.request-template .content-box {
    align-items: center;
    justify-content: center;
    background-color: var(--variable-collection-primary-color-5-20);
    border-radius: 8px 0px 0px 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    height: 184px;
    overflow: hidden;
    padding: 12px 16px;
    padding-right: 4px;
}

.request-template .content-text {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-weight);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
}

.request-template .generate-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    background: linear-gradient(to top,
            var(--variable-collection-secondary-color-50) 0%,
            var(--variable-collection-primary-color-50) 100%);
    background-color: var(--variable-collection-primary-color-50);
    border-radius: 0px 8px 8px 0px;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    width: 36px;
    cursor: pointer;
}

.request-template .generate-button:active {
    opacity: 0.7;
}

.request-template .generate-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.request-template .gb-text {
    color: var(--variable-collection-primary-color-20);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 400;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    writing-mode: sideways-lr; 
    white-space: nowrap; 
}

.request-template .spinner-wrapper {
    display: flex;
    transform: rotate(270deg);
    margin-right: 10px;
}

.request-template .input-box {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    height: 22px;
}

.request-template .input-box::placeholder {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.request-template .text-area-frame {
    border: none;
    background-color: unset;
    resize: none;
    outline: none;
    padding-right: 4px;
    width: 100%;
    height: 100%;
}

.request-template .text-area-frame::-webkit-scrollbar {
    width: 8px;
}

.request-template .text-area-frame::-webkit-scrollbar-track {
    background: transparent;
}

.request-template .text-area-frame::-webkit-scrollbar-thumb {
    background: var(--variable-collection-secondary-color-75);
    border-radius: 4px;
}

.request-template .text-area-frame::-webkit-scrollbar-thumb:hover {
    background: var(--variable-collection-secondary-color-65);
}