.request-card-mobile {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.request-card-mobile .status-frame {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 48px;
    width: 48px;
    justify-content: center;
}

.request-card-mobile .status-frame .request-status {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-70);
    cursor: auto;
}

.request-card-mobile .info {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0px 16px;
}

.request-card-mobile .curator-name {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
}

.request-card-mobile .name-text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
}

.request-card-mobile .name-text-wrapper.dull {
    color: var(--variable-collection-primary-color-60);
}

.request-card-mobile .name-text-wrapper.highlight {
    color: var(--variable-collection-secondary-color-75);
}

.request-card-mobile .name-text-wrapper.blur {
    filter: blur(4px);
}

.request-card-mobile .icon-frame {
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin: 0px 0px 0px 8px;
}

.request-card-mobile .icon-frame.medium {
    height: 20px;
    width: 20px;
}

.request-card-mobile .stack-icon-frame {
    display: flex;
    width: 16px;
    height: 16px;
}

.request-card-mobile .icon-frame.medium>svg>path,
.request-card-mobile .stack-icon-frame>svg>path {
    fill: var(--variable-collection-secondary-color-75);
}

.request-card-mobile .icon-frame>svg>path {
    fill: var(--variable-collection-primary-color-60);
}

.request-card-mobile .card-wrapper {
    display: flex;
    width: 100%;
    cursor: pointer;
}

.request-card-mobile .info-wrapper {
    display: flex;
    width: 100%;
}

.request-card-mobile .info-frame {
    align-items: flex-start;
    display: flex;
    width: 100%;
}

.request-card-mobile .info-frame .left-section {
    display: flex;
    background-color: var(--variable-collection-primary-color-5-70);
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
    width: 48px;
}

.request-card-mobile .info-frame .left-section.pending .line-arrow,
.request-card-mobile .info-frame .left-section.pending .vertical-line {
    background-color: var(--variable-collection-warning-color-60);
}

.request-card-mobile .info-frame .left-section.featured .line-arrow,
.request-card-mobile .info-frame .left-section.featured .vertical-line {
    background-color: var(--variable-collection-approve-color-60);
}

.request-card-mobile .info-frame .left-section.suspended .line-arrow,
.request-card-mobile .info-frame .left-section.suspended .vertical-line {
    background-color: var(--variable-collection-primary-color-70);
}

.request-card-mobile .info-frame .left-section.expired .line-arrow,
.request-card-mobile .info-frame .left-section.expired .vertical-line {
    background-color: var(--variable-collection-primary-color-60);
}

.request-card-mobile .info-frame .left-section.discussing .line-arrow,
.request-card-mobile .info-frame .left-section.discussing .vertical-line {
    background-color: var(--variable-collection-secondary-color-75);
}

.request-card-mobile .info-frame .vertical-line {
    display: flex;
    width: 1px;
    height: 100%;
}

.request-card-mobile .info-frame .line-arrow {
    display: flex;
    border-radius: 50%;
    width: 7px;
    min-width: 7px;
    height: 7px;
    min-height: 7px;
}

.request-card-mobile .info-frame .request-info {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-5-70);
    border-radius: 0px 0px 8px 0px;
    padding: 8px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.request-card-mobile .info-frame .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
}

.request-card-mobile .info-frame .div {
    align-items: center;
    display: inline-flex;
    gap: 4px;
}

.request-card-mobile .info-frame .playlists {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.request-card-mobile .info-frame .curator-playlists {
    align-items: center;
    align-self: stretch;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
}

.request-card-mobile .info-frame .options {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.request-card-mobile .info-frame .playlist {
    align-items: center;
    align-self: stretch;
    display: flex;
    padding: 8px 16px;
    width: 100%;
}

.request-card-mobile .info-frame .option {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    display: inline-flex;
    gap: 4px;
    cursor: pointer;
}

.request-card-mobile .info-frame .option:active {
    opacity: 0.7;
}

.request-card-mobile .info-frame .option:disabled {
    opacity: 0.5;
    cursor: auto;
}

.request-card-mobile .info-frame .option.hovered:hover .name-text-wrapper {
    color: var(--variable-collection-secondary-color-75);
}