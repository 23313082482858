.submission-track-dropdown-menu {
    align-items: flex-start;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
}

.submission-track-dropdown-menu .filter-bar {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-70);
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    gap: 16px;
    width: 100%;
    height: 48px;
}

.submission-track-dropdown-menu .filter-bar > svg > path {
    fill: var(--variable-collection-primary-color-60);
}

.submission-track-dropdown-menu .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all; 
}

.submission-track-dropdown-menu .text-wrapper.dull {
    color: var(--variable-collection-primary-color-60);
}

.submission-track-dropdown-menu .main-section {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-70);
    display: flex;
    gap: 8px;
    padding: 8px;
    width: 100%;
    height: 100%;
}

.submission-track-dropdown-menu .options {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 320px;
}

.submission-track-dropdown-menu .option {
    align-items: center;
    align-self: stretch;
    display: flex;
    width: 100%;
    cursor: pointer;
    padding-right: 16px;
}

.submission-track-dropdown-menu .option:hover .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
}

.submission-track-dropdown-menu .option.active .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
    cursor: auto;
}

.submission-track-dropdown-menu .left-section {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.submission-track-dropdown-menu .track-image {
    height: 32px;
    object-fit: cover;
    width: 32px;
    border-radius: 4px;
}

.submission-track-dropdown-menu .info {
    align-items: center;
    display: inline-flex;
    gap: 4px;
}

.submission-track-dropdown-menu .no-tracks {
    display: flex;
    align-items: center;
    padding: 32px 0px;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.submission-track-dropdown-menu .input-box {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.submission-track-dropdown-menu .clear-filter {
    all: unset;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
}

.submission-track-dropdown-menu .clear-filter:active {
    opacity: 0.7;
}

.submission-track-dropdown-menu .options::-webkit-scrollbar {
    width: 8px;
}

.submission-track-dropdown-menu .options::-webkit-scrollbar-track {
    background: transparent;
}

.submission-track-dropdown-menu .options::-webkit-scrollbar-thumb {
    background: var(--variable-collection-secondary-color-75);
    border-radius: 4px;
}

.submission-track-dropdown-menu .options::-webkit-scrollbar-thumb:hover {
    background: var(--variable-collection-secondary-color-65);
}