.requests-history {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-5-20);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-height: 480px;
}

.requests-history.mobile {
    height: 480px;
}

.requests-history .history-search-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    width: 100%;
}

.requests-history .status-filter {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-70);
    display: flex;
    height: 48px;
    justify-content: space-between;
    width: 160px;
}

.requests-history .filter-bar {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-50);
    gap: 8px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 8px;
}

.requests-history .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.requests-history .text-wrapper.dull {
    color: var(--variable-collection-primary-color-60);
}

.requests-history .text-wrapper.status-menu {
    font-weight: 400;
}

.requests-history .actions {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.requests-history .results {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.requests-history.mobile .results {
    scrollbar-width: none;
    border-radius: 0px 0px 8px 8px;
}

.requests-history.mobile .results::-webkit-scrollbar {
    display: none;
}

.requests-history .empty {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 32px 32px;
}

.requests-history .empty.text-wrapper {
    white-space: unset;
    width: 100%;
    text-align: center;
}

.requests-history .action-button {
    all: unset;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.requests-history .action-button:active {
    opacity: 0.7;
}

.requests-history .action-button>svg>path {
    fill: var(--variable-collection-primary-color-60);
}

.requests-history .icon-frame {
    display: flex;
    width: 24px;
    height: 24px;
}

.requests-history .icon-frame>svg>path {
    fill: var(--variable-collection-primary-color-60);
}

.requests-history .input-box {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.requests-history .results::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin: 8px;
}

.requests-history .results::-webkit-scrollbar-track {
    background: transparent;
}

.requests-history .results::-webkit-scrollbar-thumb {
    background: var(--variable-collection-secondary-color-75);
    border-radius: 4px;
}

.requests-history .results::-webkit-scrollbar-thumb:hover {
    background: var(--variable-collection-secondary-color-65);
}

.requests-history .results::-webkit-scrollbar-corner {
    background: transparent;
}

@media only screen and (max-width: 1049px) {
    .requests-history .status-filter {
        width: 48px;
    }
}