.playlists-menu {
    align-items: flex-start;
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 164px;
    max-width: 300px;
    max-height: 400px;
}

.playlists-menu .header {
    align-items: center;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-5-70);
    display: flex;
    padding: 16px;
    width: 100%;
}

.playlists-menu .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
}

.playlists-menu  .text-wrapper.dull {
    color: var(--variable-collection-primary-color-60);
}

.playlists-menu .options {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-5-70);
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 16px 16px;
    min-width: 164px;
    overflow-y: auto;
    overflow-x: hidden;
}

.playlists-menu .option {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    display: inline-flex;
    gap: 4px;
    cursor: pointer;
}

.playlists-menu .option:active {
    opacity: 0.7;
}

.playlists-menu .option:hover .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
}

.playlists-menu .options::-webkit-scrollbar {
    width: 8px;
}

.playlists-menu .options::-webkit-scrollbar-track {
    background: transparent;
}

.playlists-menu .options::-webkit-scrollbar-thumb {
    background: var(--variable-collection-secondary-color-75);
    border-radius: 4px;
}

.playlists-menu .options::-webkit-scrollbar-thumb:hover {
    background: var(--variable-collection-secondary-color-65);
}