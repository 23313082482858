.side-menu-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
    height: 48px;
    padding: 8px 0px;
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
    cursor: default;
    text-decoration: none;
    cursor: pointer;
}

.side-menu-button .icon-frame {
    display: flex;
    width: 32px;
    height: 32px;
}

.side-menu-button.default .icon-frame > svg > path {
    color: var(--variable-collection-primary-color-60);
}

.side-menu-button.active .icon-frame > svg > path {
    color: var(--variable-collection-primary-color-95);
    pointer-events: none;
}

.side-menu-button:hover .icon-frame > svg > path {
    color: var(--variable-collection-primary-color-95);
}

.side-menu-button:disabled, .side-menu-button[disabled] {
    color: var(--variable-collection-primary-color-60);
    pointer-events: none;
}

.side-menu-button .text-wrapper {
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.side-menu-button.default .text-wrapper {
    color: var(--variable-collection-primary-color-60);
}

.side-menu-button.active .text-wrapper {
    color: var(--variable-collection-primary-color-95);
}

.side-menu-button:hover .text-wrapper {
    color: var(--variable-collection-primary-color-95);
}