.status-dropdown {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-50);
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 8px 16px;
    width: 160px;
    cursor: pointer;
}

.status-dropdown.mobile {
    background-color: unset;
    width: 48px;
    padding: 0px 0px;
    justify-content: center;
}

.status-dropdown .text-wrapper {
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 400;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.status-dropdown-menu {
    align-items: flex-start;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 160px;
}

.status-dropdown-menu.mobile {
    border-radius: 0px 0px 8px 0px;
}

.status-dropdown-menu.top {
    border-radius: 8px 8px 8px 8px;
}

.status-dropdown-popup-wrapper {
    display: flex;
    width: 100%;
}

.status-dropdown .text-wrapper.pending {
    color: var(--variable-collection-warning-color-60);
}

.status-dropdown .text-wrapper.featured {
    color: var(--variable-collection-approve-color-60);
}

.status-dropdown .text-wrapper.suspended {
    color: var(--variable-collection-primary-color-70);
}

.status-dropdown .text-wrapper.expired {
    color: var(--variable-collection-primary-color-60);
}

.status-dropdown .text-wrapper.discussing {
    color: var(--variable-collection-secondary-color-75);
}

.status-dropdown-menu .request-status {
    background-color: var(--variable-collection-primary-color-5-70);
    -webkit-backdrop-filter: blur(4px) brightness(100%);
    backdrop-filter: blur(4px) brightness(100%);
}

.status-dropdown.mobile .request-status {
    background-color: var(--variable-collection-primary-color-5-70);
}