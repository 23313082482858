.artist-selection {
  align-items: flex-end;
  background-color: var(--variable-collection-background-section-color);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color-50);
  border-radius: 16px;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 8px 16px;
  max-height: 400px;
  min-width: 288px;
}

.artist-selection .card-box {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}

.artist-selection ::-webkit-scrollbar {
  width: 16px;
}

.artist-selection ::-webkit-scrollbar-track {
  background: transparent;
}

.artist-selection ::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.artist-selection ::-webkit-scrollbar-thumb:hover {
  background: transparent;
  box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}