.messenger-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
}

.messenger-box .message-box {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 80px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.messenger-box .message-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    width: 100%;
    margin-top: 8px;
}

.message-box .message-section.stacked {
    margin-top: 2px;
}

.messenger-box .selection {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 32px;
}

.messenger-box .message-card-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.messenger-box .message-card-wrapper.left {
    align-items: flex-start;
}

.messenger-box .message-card-wrapper.right {
    align-items: flex-end;
}

.messenger-box .bottom-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;
    padding-right: 80px;
    padding-left: 80px;
}

.messenger-box .sender {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
}

.messenger-box .empty-box {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    justify-content: center;
    width: 100%;
}

.messenger-box .subtitle {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
  }

.messenger-box .text-box {
    align-content: center;
    min-height: 48px;
    max-height: 280px;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    background-color: var(--variable-collection-primary-color-10);
    border: none;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    width: 100%;
    resize: none;
    outline: none;
}

.messenger-box .loading-box {
    padding: 16px;
}

.messenger-box .text-box::-webkit-scrollbar,
.messenger-box .message-box::-webkit-scrollbar {
    width: 8px;
}

.messenger-box .text-box::-webkit-scrollbar-track,
.messenger-box .message-box::-webkit-scrollbar-track {
    background: none;
}

.messenger-box .text-box::-webkit-scrollbar-thumb,
.messenger-box .message-box::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

.messenger-box .text-box::-webkit-scrollbar-thumb:hover,
.messenger-box .message-box::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 0px solid transparent;
    border-right: 2px solid transparent;
}

@media only screen and (max-width: 1049px) {
    .messenger-box .message-box,
    .messenger-box .bottom-section {
        padding: 0px 0px;
    }
}

