.footer-component {
    align-items: center;
    background-color: var(--variable-collection-primary-color-25-3);
    -webkit-backdrop-filter: blur(3px) brightness(100%);
    backdrop-filter: blur(3px) brightness(100%);
    border-radius: 8px;
    display: flex;
    width: 100%;
}

.footer-component.desktop {
    padding: 16px 48px;
}

.footer-component.mobile {
    padding: 16px 24px;
}

.footer-component .text-wrapper {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    width: fit-content;
}

.footer-component .link {
    all: unset;
    box-sizing: border-box;
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
}

.footer-component .link:hover {
    color: var(--variable-collection-primary-color-95);
    text-decoration: underline;
}

.footer-component .left-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
    position: relative;
}

.footer-component .right-section {
    align-items: center;
    display: inline-flex;
    position: relative;
}

.footer-component.desktop {
    flex-wrap: wrap;
    gap: 16px 16px;
    justify-content: space-between;
}

.footer-component.mobile {
    flex-direction: column-reverse;
    gap: 16px;
}

.footer-component.desktop .right-section {
    gap: 16px;
}

.footer-component.mobile .right-section {
    max-width: 295px;
    width: 100%;
    justify-content: space-between;
}

@media only screen and (max-width: 390px) {
    .footer-component.mobile {
        gap: 8px;
    }
    .footer-component.mobile .right-section {
        max-width: none;
    }
}