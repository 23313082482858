.side-primary-menu {
  align-items: center;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  align-self: stretch;
  max-width: 208px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.side-primary-menu .stats-menu {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 1049px) and (min-width: 693px) {
  .side-primary-menu {
    align-self: auto;
  }
}

@media only screen and (max-width: 692px) and (min-width: 617px) {
  .side-primary-menu {
    width: 218px;
    max-width: 218px;
  }
}

@media only screen and (max-width: 616px) {
  .side-primary-menu {
    border: 2px solid;
    border-color: var(--variable-collection-secondary-color-50);
  }
}